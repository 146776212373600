export function ServiceDisplayName(name) {
  var text
  switch (name) {
    case "email":
      text = "Email"
      break
    case "gitlab":
      text = "Git"
      break
    case "artifactory":
      text = "Artifacts"
      break
    case "irc":
      text = "IRC"
      break
    case "wiki":
      text = "Wiki"
      break
    case "servicedesk":
      text = "Support"
      break
    default:
      text = name
  }
  return text
}

export function ServiceURI(name) {
  var link
  switch (name) {
    case "email":
      link = "https://mail.codelinaro.org"
      break
    case "gitlab":
      link = "https://staging-git.codelinaro.org"
      break
    case "artifactory":
      link = "https://binaryartifacts.jfrog.io/"
      break
    default:
      link = `https://${name}.codelinaro.org/`
  }
  return link
}
